// Libs
import React from "react";

import { ContentLayout } from "../layouts/content-layout";
import { DefaultLayout } from "../layouts/default-layout";

const browser = typeof window !== "undefined" && window;

const NotFound = ({ children }) => {
    return (
        browser && (
            <DefaultLayout pageDetails={{ title: "Page Not Found" }}>
                <ContentLayout title="Page Not Found">
                    <div className="g-flex__container g-flex__container--column-always u-padding-top--2">
                        <span className="g-flex__item e-fake-heading">Oops! We seem to have misplaced this page...</span>
                        <span className="u-padding-top--1 g-flex__item">Please visit another page whilst we try and fix the issue.</span>
                    </div>
                    {children}
                </ContentLayout>
            </DefaultLayout>
        )
    );
};

export default NotFound;